.rcms-switch-brand {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      & + .rcms-switch__slider {
        background-color: $color-brand;
        &:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
    &:focus {
      & + .slider {
        box-shadow: 0 0 4px $color-brand;
      }
    }
  }

  .rcms-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
    border-radius: 4px;
    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
      border-radius: 3px;
    }
  }
  .rcms-switch__round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
