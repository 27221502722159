.dropdown-toggle[data-no-icon='true']::after {
  display: none;
}

.p-element .p-inputtext .p-component {
  padding: 0.4rem;
}

.pagination {
  margin-bottom: 0 !important;

  .page-item {
    .page-link {
      border-radius: 2px !important;
      border-style: none !important;
      color: $color-brand !important;
    }
  }

  .active {
    .page-link {
      background-color: $color-brand !important;
      color: white !important;
    }
  }

  .disabled {
    .page-link {
      background-color: transparent;
      color: var(--bs-gray-500) !important;
    }
  }
}

.rcms-modal__loading {
  .modal-content {
    background-color: transparent !important;
    border-color: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.rcms-nav__tabs {
  .nav-link {
    color: rgba(0, 0, 0, 0.55);
    &:hover {
      color: $color-brand !important;
    }
  }
}

.rcms-radio-btn-brand {
  .form-check-input {
    &:checked {
      background-color: $color-brand !important;
      border-color: $color-brand !important;
      opacity: 0.75;
    }
  }

  .form-check-input:disabled ~ .form-check-label {
    opacity: 0.75;
  }
}

.table {
  --bs-table-color: $color-text-darken !important;
}

.treeview-item {
  .row-item {
    display: inline-flex;
  }

  .form-check-input {
    &:checked {
      background-color: $color-brand !important;
      border-color: $color-brand !important;
      opacity: 0.75;
    }
  }

  .form-check-input:disabled ~ .form-check-label {
    opacity: 0.75;
  }
}
