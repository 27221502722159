/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

// bootstrap custom style
@import './app/stylesheet/bootstrapping';

/* Importing Bootstrap SCSS file. */
@import './node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';
